import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import Typography from "../../components/Typography";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";

SwiperCore.use([Navigation]);

export const PartnersTemplate = ({ seo, title, subtitle, testimonials, sections, location }) => {
  return (
    <Layout location={location}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: "description",
            content: seo?.description,
          },
        ]}
      />
      <Grid container className="mt-172 mb-80">
        <Grid row direction="column" align="center">
          <Grid column md={6}>
            <Typography variant="h1" align="center" className="mb-16">
              {title}
            </Typography>
            <Typography variant="subtitle" align="center" color="neutral-50" className="mb-48">
              {subtitle}
            </Typography>
          </Grid>
          <Grid column xs={12} lg={10}>
            <Swiper navigation loop spaceBetween={56}>
              {testimonials.map((testimonial) => (
                <SwiperSlide>
                  <div className="py-32 px-16 px-sm-32 px-md-72">
                    <Card elevation={2}>
                      <div className="d-flex flex-wrap flex-sm-nowrap flex-column flex-sm-row">
                        <PreviewCompatibleImage
                          className="testimonial-image"
                          imageInfo={{ image: testimonial?.picture, alt: testimonial?.name }}
                          objectFit="cover"
                          objectPosition="50% 0%"
                        />
                        <CardBody>
                          <Typography variant="subtitle" className="mb-8">
                            {testimonial?.name}
                          </Typography>
                          <Typography variant="paragraph-2" className="mb-16">
                            {testimonial?.position}
                          </Typography>
                          <Typography variant="paragraph-1">{testimonial?.text}</Typography>
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Grid>
      {(sections ?? []).map((section) => (
        <Grid key={section?.title} container className="pb-48" component="section">
          <Typography variant="h2" className="mb-24" align="center">
            {section?.title}
          </Typography>
          <Grid row spacing={8}>
            {(section?.logos ?? []).map((partner) => {
              return (
                <Grid column xs={4} md={3} lg={2} key={partner?.name}>
                  <a href={partner?.url} title={partner?.title} className="d-block brand-wrapper p-16">
                    <PreviewCompatibleImage imageInfo={{ image: partner?.logo, alt: partner?.name }} />
                  </a>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ))}
    </Layout>
  );
};

PartnersTemplate.propTypes = {
  seo: PropTypes.object,
  sections: PropTypes.array,
  location: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  testimonials: PropTypes.array,
};

PartnersTemplate.defaultProps = {
  seo: {},
  sections: [],
  title: null,
  subtitle: null,
  testimonials: [],
  location: undefined,
};

const PartnersPage = ({ data, location }) => {
  const { frontmatter } = data?.markdownRemark || {};
  const { seo, partners } = frontmatter ?? {};
  const { sections, title, subtitle, testimonials } = partners ?? {};

  return (
    <PartnersTemplate
      seo={seo}
      sections={sections}
      title={title}
      subtitle={subtitle}
      testimonials={testimonials}
      location={location}
    />
  );
};

PartnersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
};

PartnersPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
    },
  },
  location: undefined,
};

export default PartnersPage;

export const pageQuery = graphql`
  query partnersPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        partners {
          title
          subtitle
          testimonials {
            name
            position
            picture {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
          sections {
            title
            logos {
              name
              logo {
                childImageSharp {
                  fluid(maxWidth: 320, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;
